import React from "react";
import Header from "./Header";
import Content from "./Content";
import {useStaticQuery, graphql} from "exo";
import Think from "../home/Think";

const Index = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allExoMemberBenifit {
        edges {
          node {
            sections {
              ... on SanityContentSection {
                _key
                mention
                description
                _rawBody
                mainImage {
                  alt
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const memberbenefitData = data.allExoMemberBenifit.edges[0].node;

  return (
    <div>
      <Header />
      <Content memberbenefitData={memberbenefitData} />
      <Think />
    </div>
  );
};

export default Index;
